.input-bot-boder {
  outline: none;
  border: none;
  font-size: 16px;
  transition: border 0.4s;
  width: 100%;
  background: #f7f7f8;
  border-radius: 5px;
  padding: 10px 20px;
}

/* .input-bot-boder:focus,
.input-bot-boder.active {
  border-bottom: 1px solid #dc3546;
} */

.input-bot-boder::placeholder {
  color: #a9a9a9;
}

.select-bot-bodar {
  outline: none !important;
  border: none !important;
  border-bottom: 1px solid #e2e2ea !important;
  border-radius: 6px !important;
  box-shadow: none !important;
  background-color: #f7f7f8 !important;
  font-size: 14px !important;
  padding: 12px 10px !important;
}

.select-bot-bodar:focus {
  border-bottom: 1px solid #dc3546 !important;
}

.select-bot-bodar:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.input-bot-label {
  color: #a9a9a9;
  font-size: 12px;
  display: block;
  margin-bottom: 10px;
  margin-top: 15px;
}

.anchor-red {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  color: #dc3546;
  text-decoration: none;
  margin-top: 14px;
  margin-bottom: 14px;
}

.anchor-red:hover {
  color: #b42c3a;
}

.button-boder {
  border: none;
  outline: none;
  background-color: #b72949;
  -width: 250px;
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
  border-radius: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
  padding-right: 20px;
  padding-left: 20px;
  text-transform: uppercase;
}
.button-boder,
.long-btn-speed {
}

.button-boder-small {
  font-size: 13px;
  padding: 7px 16px 6px 16px;
}

@media (max-width: 750px) {
  .button-boder {
    width: 100%;
  }
}

.button-boder:hover,
.button-boder.active {
  background-color: #dc3456;
}

.button-boder:disabled {
  background-color: rgb(229 102 130);
  cursor: not-allowed;
}

.button-outline-boder {
  border: none;
  outline: none;
  background-color: transparent;
  width: 100%;
  font-weight: 500;
  font-size: 15px;
  color: #b72949;
  border-radius: 10px;
  padding-top: 18px;
  padding-bottom: 18px;
  cursor: pointer;
  background-color: white;
  border: 1px solid #b72949;
  transition: border 0.4s;
  text-transform: uppercase;
}

.button-outline-boder:hover,
.button-outline-boder.active {
  border: 1px solid #b72949;
  background-color: #b7294966;
  color: white;
}

.warning-error {
  background-color: #fda03f;
  color: white;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #ddb08f;
}

.border-outline {
  outline: 1px solid red;
  outline-offset: -1px;
}

.no-offers-text {
  text-align: center;
  margin-top: 80px;
}

.chat-footer {
  margin-top: 2px;
  padding-bottom: 5px;
}

.send-msg-button-chat {
  display: flex;
  font-weight: bold;
  padding: 16px 12px;
  color: #b72949;
  cursor: pointer;
}

.send-msg-button-chat img {
  margin-left: 15px;
  margin-right: 15px;
}

.chat-footer .chat-input-container {
  border-radius: 10px;
  padding: 5px;
  background: #fafafb;
}

.chat-footer .chat-text-input {
  border: none;
  resize: none;
  box-shadow: none !important;
  outline: none;
  transition: all 0.3s;
  background: inherit;
  width: 100%;
}

textarea.chat-text-input:not(:focus)::placeholder {
  padding-top: 11px;
}

.textarea-boder-input {
  width: 100%;
  background: #f7f7f8;
  border-radius: 5px;
  min-height: 80px;
  padding: 5px 10px;
  resize: none;
  border: none;
}

.profile-card-o2 {
  background: #ffffff;
  box-shadow: 3px -5px 40px rgba(205, 205, 212, 0.1);
  border-radius: 20px;
  padding: 30px;
}

.person-icon-as-background {
  padding-left: 30px;
  background-position-y: center;
  background-repeat: no-repeat;
  background-position-x: 5px;
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='16' viewBox='0 0 13 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.4742 4.51961C2.4742 2.29964 4.23923 0.5 6.4165 0.5C8.59378 0.5 10.3588 2.29964 10.3588 4.51961C10.3588 5.91354 9.66292 7.14175 8.60603 7.86278C10.9779 8.76743 12.6665 11.0993 12.6665 13.8333V15.5H0.166504V13.8333C0.166504 11.0993 1.85512 8.76743 4.22698 7.86278C3.17009 7.14175 2.4742 5.91354 2.4742 4.51961ZM6.4165 6.77451C7.6379 6.77451 8.62804 5.76496 8.62804 4.51961C8.62804 3.27426 7.6379 2.26471 6.4165 2.26471C5.19511 2.26471 4.20497 3.27426 4.20497 4.51961C4.20497 5.76496 5.19511 6.77451 6.4165 6.77451ZM6.4165 9.22549C3.95273 9.22549 1.94945 11.2357 1.89828 13.7353H10.9347C10.8836 11.2357 8.88028 9.22549 6.4165 9.22549Z' fill='%2392929D'/%3E%3C/svg%3E%0A");
}

.zip-icon-as-background {
  padding-left: 30px;
  background-position-y: center;
  background-repeat: no-repeat;
  background-position-x: 3px;
  background-image: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.50016 17.0002C3.04183 17.0002 2.64961 16.8371 2.3235 16.511C1.99683 16.1844 1.8335 15.7919 1.8335 15.3335V5.33354C1.8335 4.8752 1.99683 4.48298 2.3235 4.15687C2.64961 3.8302 3.04183 3.66687 3.50016 3.66687H8.50016L10.1668 5.33354H16.8335C17.2918 5.33354 17.6843 5.49687 18.011 5.82354C18.3371 6.14965 18.5002 6.54187 18.5002 7.0002V15.3335C18.5002 15.7919 18.3371 16.1844 18.011 16.511C17.6843 16.8371 17.2918 17.0002 16.8335 17.0002H3.50016ZM13.5002 15.3335H16.8335V7.0002H13.5002V8.66687H15.1668V10.3335H13.5002V12.0002H15.1668V13.6669H13.5002V15.3335ZM3.50016 15.3335H11.8335V13.6669H13.5002V12.0002H11.8335V10.3335H13.5002V8.66687H11.8335V7.0002H9.47933L7.81266 5.33354H3.50016V15.3335ZM3.50016 7.0002V5.33354V15.3335V7.0002Z' fill='%2392929D'/%3E%3C/svg%3E%0A");
}

.search-icon-as-background {
  background: white;
  padding-left: 30px;
  background-position-y: center;
  background-repeat: no-repeat;
  background-position-x: 9px;
  border: 1px solid rgba(195, 212, 233, 0.4) !important;
  border-radius: 7px;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.66665 14C11.1644 14 14 11.1644 14 7.66665C14 4.16884 11.1644 1.33331 7.66665 1.33331C4.16884 1.33331 1.33331 4.16884 1.33331 7.66665C1.33331 11.1644 4.16884 14 7.66665 14Z' stroke='%233D5278' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14.6666 14.6666L13.3333 13.3333' stroke='%233D5278' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.email-icon-as-background {
  padding-left: 30px;
  background-position-y: center;
  background-repeat: no-repeat;
  background-position-x: 3px;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='15' viewBox='0 0 20 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.33357 0.333374H16.6669C18.0476 0.333374 19.1669 1.45266 19.1669 2.83337V3.18965C19.167 3.19687 19.167 3.20409 19.1669 3.2113V12C19.1669 13.3808 18.0476 14.5 16.6669 14.5H3.33357C1.95286 14.5 0.833567 13.3808 0.833567 12V3.2114C0.833472 3.20412 0.833472 3.19684 0.833567 3.18956V2.83337C0.833567 1.45266 1.95286 0.333374 3.33357 0.333374ZM2.50023 4.80175V12C2.50023 12.4603 2.87333 12.8334 3.33357 12.8334H16.6669C17.1271 12.8334 17.5002 12.4603 17.5002 12V4.80175L11.4339 9.04819C10.5731 9.65075 9.42738 9.65075 8.56658 9.04819L2.50023 4.80175ZM17.4978 2.76904L10.4781 7.68281C10.1912 7.88366 9.80928 7.88366 9.52235 7.68281L2.50268 2.76904C2.53553 2.33886 2.89498 2.00004 3.33357 2.00004H16.6669C17.1055 2.00004 17.4649 2.33886 17.4978 2.76904Z' fill='%2392929D'/%3E%3C/svg%3E%0A");
}

.lock-icon-as-background {
  padding-left: 30px;
  background-position-y: center;
  background-repeat: no-repeat;
  background-position-x: 3px;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.85483 7.4364V5.91176C5.85483 3.56975 7.7345 1.66663 10.0589 1.66663C12.3833 1.66663 14.263 3.56975 14.263 5.91176V7.4364H15.4529C16.1293 7.4364 16.6668 7.99131 16.6668 8.66306V17.1066C16.6668 17.7784 16.1293 18.3333 15.4529 18.3333H4.54739C3.87103 18.3333 3.3335 17.7784 3.3335 17.1066V8.66306C3.3335 7.99131 3.87103 7.4364 4.54739 7.4364H5.85483ZM4.85248 8.95155V16.8181H15.1478V8.95155H4.85248ZM12.744 7.41305V5.91176C12.744 4.40152 11.5393 3.18178 10.0589 3.18178C8.57853 3.18178 7.37381 4.40152 7.37381 5.91176V7.41305H12.744Z' fill='%2392929D'/%3E%3C/svg%3E%0A");
}

.card-search-price {
  font-size: 1.2rem;
  font-weight: bold;
  position: relative;
}
.card-search-price::before {
  content: ""; /*Asking price*/
  top: 0px;
  left: 0px;
  position: absolute;
  font-size: 10px;
  transform: translateY(-95%);
  color: #619c6f;
}

.progress-over-image {
  --width-progress: 50%;
  width: var(--width-progress);
  position: absolute;
  height: 100%;
  backdrop-filter: brightness(0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: 23px;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  transition: all 0.6s;
}

.italic-info {
  color: #000000;
  font-size: 10px;
  display: block;
  margin-bottom: 10px;
  font-style: italic;
  /* margin-top: 25px; */
}

.container-list-of-cards {
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
}

.card-o2 {
  box-shadow: rgb(0 0 0 / 35%) 1px 4px 13px -5px;
  overflow: hidden;
  width: 200px;
  flex-basis: 200px;
  margin-bottom: 25px;
  height: 260px;
  flex-grow: 1;
  border-radius: 12px;
  flex-shrink: 0;
  margin-left: 5px;
  margin-right: 5px;
  padding: 10px;
}
.card-o2-image-container {
  width: 100%;
  overflow: hidden;
  height: 180px;
  position: relative;
  border-radius: 14px;
}
.card-o2 img {
  object-fit: cover;
  width: 100%;
  height: 200px;
  max-width: 100%;
}

.card-o2-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}
.card-o2-location {
  text-overflow: ellipsis;
  font-size: 15px;
}

.card-o2-image-container::before {
  content: "";
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  background: url("/public/images/sold.png");
  background-size: 100% 70%;
  background-position-y: 50%;
  background-repeat: no-repeat;
  display: flex;
  height: 100%;
  color: #dc3546;
  left: 0;
  font-weight: bolder;
  font-size: 22px;
  text-shadow: -1px -1px 0 #ffffff9f, 1px -1px 0 #ffffff9f, -1px 1px 0 #ffffff9f, 1px 1px 0 #ffffff9f;
}

.card-search-oneCard {
  width: 100%;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 10px 10px 10px;
  position: relative;
  cursor: pointer;
  transition: all 0.6s;
  max-width: 400px;
}
.price-card-search-value-market {
  font-size: 11px;
}
.price-card-search-value-text {
  text-transform: uppercase;
  font-weight: 600;
  text-align: right;
  font-size: 16px;
}
.deal-0 {
  color: red;
}
.deal-1 {
  color: red;
}
.deal-2 {
  color: orange;
}
.deal-3 {
  color: green;
}
.deal-4 {
  color: greenyellow;
}
.card-search-img-container img {
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  height: 250px;
}
.button-exist-button-invisible {
  visibility: hidden;
  opacity: 0.01;
}
.card-search-title,
.card-search-location {
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  margin-top: 4px;
}
.card-serach-title {
  font-weight: bold;
}
.card-search-location {
  font-size: 11px;
}

.point-of-box {
  position: relative;
}
span.point-of-box::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0px;
  bottom: 0px;
  width: 10%;
  background: linear-gradient(90deg, rgba(24, 82, 125, 0) 0%, rgba(255, 255, 255, 1) 100%);
}

.bar-header-tri {
  min-height: 80px;
  border-radius: 16px;
  background: linear-gradient(140deg, #961330 25%, #b72949 25%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2rem;
  color: white;
  padding-right: 2rem;
  font-size: 22px;
}

.bar-tri-container {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: column;
  background: rgba(183, 41, 73, 0.03);
  border: 1px dashed rgba(133, 133, 133, 0.23);
  border-radius: 10px;
  min-height: 50vh;
}

/* sm size:  */
@media (min-width: 0px) {
  .w-sm-100 {
    width: 100% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }
}

/* md size:  */
@media (min-width: 768px) {
  .w-md-100 {
    width: 100% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }
}

/* lg size: */
@media (min-width: 992px) {
  .w-md-100 {
    width: 100% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }
}

/* xxl size */
@media (min-width: 1200px) {
  .w-xxl-100 {
    width: 100% !important;
  }

  .w-xxl-75 {
    width: 75% !important;
  }

  .w-xxl-50 {
    width: 50% !important;
  }
}
